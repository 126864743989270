<template>
  <div class="w-full md:w-1/4 xl:w-1/4 p-3">
    <router-link :to="route" class="font-bold uppercase homepageLink">
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div :class="`rounded p-3 ${cardColor}`">
              <FontAwesomeIcon :icon="icon" class="text-3xl" />
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <router-link :to="route" class="font-bold uppercase homepageLink">{{
              type
            }}</router-link>
            <h3 v-if="errors" class="font-bold text-3xl">
              {{ amount }}
              <FontAwesomeIcon icon="exchange-alt" />
            </h3>
            <h3 v-else class="font-bold text-3xl">
              <FontAwesomeIcon icon="exclamation-triangle" />
            </h3>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    type: String,
    errors: Boolean,
    amount: String,
    cardColor: String,
    route: String
  }
};
</script>

<style lang="scss" scoped></style>
