<template>
  <!--Quickview Card-->
  <div class="bg-white border rounded shadow quickview relative">
    <div class="border-b p-3">
      <router-link to="/account-info" class="font-bold uppercase homepageLink"
        >Account Info</router-link
      >
    </div>
    <UserDetails />
    <router-link
      to="/account-info"
      class="text-gray-500 p-5 italic absolute bottom-0 right-0"
      >Go to account info</router-link
    >
  </div>
</template>

<script>
import UserDetails from '@/components/user/UserDetails.vue';

export default {
  components: {
    UserDetails
  },
  methods: {
    copy (text) {
      const copyField = document.createElement('textarea');
      document.body.appendChild(copyField);
      copyField.value = text;
      copyField.select();
      document.execCommand('copy');
      document.body.removeChild(copyField);
    }
  }
};
</script>

<style lang="scss" scoped></style>
