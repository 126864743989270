<template>
  <div class="bg-white border rounded shadow quickview relative">
    <div class="border-b p-3">
      <router-link to="/logs" class="font-bold uppercase homepageLink"
        >Logs Quickview</router-link
      >
    </div>
    <div v-if="logs.length === 0" class="p-5">No Logs Available</div>
    <div v-else class="p-5 overflow-auto quickview-content">
      <table>
        <th class="w-1/4 text-left font-black">Device ID</th>
        <th class="w-1/4 text-left font-black">Received Time</th>
        <th class="w-1/4 text-left font-black">Message</th>
        <tr v-for="log in logs" :key="log.deviceId">
          <td>{{ log.deviceId }}</td>
          <td>{{ log.receivedTime }}</td>
          <td class="text-center">{{ log.message }}</td>
        </tr>
      </table>
    </div>
    <router-link
      to="/logs"
      class="text-gray-500 p-5 italic absolute bottom-0 right-0"
      >View logs page</router-link
    >
  </div>
  <!--/Quickview Card-->
</template>

<script>
export default {
  computed: {
    logs () {
      return this.$store.state.logs.slice(0, 6);
    }
  }
};
</script>
