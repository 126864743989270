<template>
  <!--Oral-B Validations Quickview Card-->
  <div class="bg-white border rounded shadow quickview relative">
    <div class="border-b p-3">
      <router-link to="/devices" class="font-bold uppercase homepageLink"
        >Validations Quickview</router-link
      >
    </div>
    <div v-if="validations.length === 0" class="p-5">No Validations Found</div>
    <div v-else class="p-5 overflow-auto quickview-content">
      <!-- orab table -->
      <table
        v-if="$store.state.brand.includes('Oral-B')"
      >
        <th class="w-1/5 text-left font-black">Device ID</th>
        <th class="w-1/5 text-left font-black">Field</th>
        <th class="w-1/5 text-left font-black">Value</th>
        <th class="w-1/5 text-left font-black">Message</th>
        <tr v-for="validation in validations" :key="validation.time">
          <td>{{ validation.data.deviceId }}</td>
          <td>{{ validation.data.field }}</td>
          <td>{{ validation.data.fieldValue }}</td>
          <td>{{ validation.data.message }}</td>
        </tr>
      </table>
    </div>
    <router-link
      to="/validations"
      class="text-gray-500 p-5 italic absolute bottom-0 right-0"
      >View validations page</router-link
    >
  </div>
</template>

<script>
export default {
  computed: {
    validations () {
      return this.$store.state.validations;
    }
  }
};
</script>
