<template>
  <div>
    <div class="container w-full mx-auto pt-20">
      <div
        class="w-full px-4 md:px-0 md:mt-8 mb-16 text-gray-800 leading-normal"
      >
        <div class="flex flex-wrap" data-cy="metric-cards">
          <MetricCard
            cardColor="bg-galaxyGreen"
            icon="tablet-alt"
            :errors="!$store.state.devicesError.length"
            type="Devices"
            route="/devices"
            :amount="$store.state.devices.length"
            data-cy="device-metric-card"
          />
          <MetricCard
            cardColor="bg-solsticeBlue"
            icon="clipboard-list"
            :errors="!$store.state.sessionsError.length"
            type="Sessions"
            route="/sessions"
            :amount="$store.state.sessions.length"
          />
          <MetricCard
            cardColor="bg-starryYellow"
            icon="list"
            :errors="!$store.state.logsError.length"
            type="Logs"
            route="/logs"
            :amount="$store.state.logs.length"
          />
          <MetricCard
            cardColor="bg-lightyearPurple"
            icon="rocket"
            :errors="!$store.state.validationsErrors.length"
            type="Validations"
            route="/validations"
            :amount="$store.state.validations.length"
          />

          <!--Divider-->
          <hr class="border-b-2 border-gray-400 my-8 mx-4 w-full" />

          <div class="flex flex-row flex-wrap flex-grow mt-2">
            <div class="w-full md:w-1/2 p-3">
              <AccountInfo />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <DevicesQuickview :brand="$store.state.brand" />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <SessionsQuickview />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <LogsQuickview />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <ValidationsQuickview />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetricCard from '@/components/homepage/MetricCard.vue';
import AccountInfo from '@/components/homepage/AccountInfo.vue';
import DevicesQuickview from '@/components/homepage/DevicesQuickview.vue';
import SessionsQuickview from '@/components/homepage/SessionsQuickview.vue';
import LogsQuickview from '@/components/homepage/LogsQuickview.vue';
import ValidationsQuickview from '@/components/homepage/ValidationsQuickview.vue';

export default {
  components: {
    MetricCard,
    AccountInfo,
    DevicesQuickview,
    SessionsQuickview,
    LogsQuickview,
    ValidationsQuickview
  }
};
</script>

<style>
.homepageLink {
  text-decoration: none !important;
  color: gray !important;
}
FontAwesomeIcon {
  height: 100px;
}
th,
td {
  padding: 2px 3px;
}
.quickview {
  height: 475px;
}
.quickview-content {
  height: 380px;
}
</style>
