<template>
  <!--Oral-B Sessions Quickview Card-->
  <div class="bg-white border rounded shadow quickview relative">
    <div class="border-b p-3">
      <router-link to="/sessions" class="font-bold uppercase homepageLink"
        >Sessions Quickview</router-link
      >
    </div>
    <div v-if="sessions.length === 0" class="p-5">No Sessions Available</div>
    <div v-else class="p-5 overflow-auto quickview-content">
      <!-- Oral-B table -->
      <table
        v-if="$store.state.brand.includes('Oral-B')"
      >
        <th class="w-1/4 text-left font-black">Device ID</th>
        <th class="w-1/4 text-left font-black">Session ID</th>
        <th class="w-1/4 text-left font-black">Session Start Time</th>
        <th class="max-w-1/4 text-left font-black">Brushing Duration</th>
        <tr v-for="session in sessions" :key="session.sessionId">
          <td>{{ session.deviceId }}</td>
          <td>{{ session.sessionId }}</td>
          <td>
            <TwoDates :date="session.sessionStartTime" />
          </td>
          <td class="w-0">{{ session.brushingDuration }}</td>
        </tr>
      </table>

      <!-- Opte table -->
      <table v-if="$store.state.brand === $store.state.brandConstants.opte">
        <th class="w-1/4 text-left font-black">Device ID</th>
        <th class="w-1/4 text-left font-black">Session ID</th>
        <th class="w-1/4 text-left font-black">Base Firmware Version</th>
        <th class="max-w-1/4 text-left font-black">Base Hardware Version</th>
        <tr v-for="session in sessions" :key="session.sessionId">
          <td>{{ session.sessionId }}</td>
          <td>{{ session.sessionDuration }}</td>
          <td class="text-center">{{ session.baseFirmwareVersion }}</td>
          <td class="w-0">{{ session.baseHardwareModel }}</td>
        </tr>
      </table>

      <!-- Gillette table -->
      <table v-if="$store.state.brand.includes('Gillette')">
        <th class="w-1/4 text-left font-black">Device ID</th>
        <th class="w-1/4 text-left font-black">Session ID</th>
        <th class="w-1/4 text-left font-black">Blade Use</th>
        <th class="max-w-1/4 text-left font-black">Blade Eject Time</th>
        <tr v-for="session in sessions" :key="session.sessionId">
          <td>{{ session.deviceId }}</td>
          <td>{{ session.sessionId }}</td>
          <td class="text-center">{{ session.bladeUse }}</td>
          <td class="w-0">{{ session.bladeEjectTime }}</td>
        </tr>
      </table>

      <!-- Labs table -->
      <table v-if="$store.state.brand.includes('Labs')">
        <th class="w-1/5 text-left font-black">Session ID</th>
        <th class="w-1/5 text-left font-black">Experiment Name</th>
        <th class="w-1/5 text-left font-black">Thing Name</th>
        <th class="w-1/5 text-left font-black">Received Time</th>
        <tr v-for="session in sessions" :key="session.sessionId">
          <td>{{ session.sessionId }}</td>
          <td>{{ session.message }}</td>
          <td>{{ session.thingName }}</td>
          <td>{{ session.receivedTime }}</td>
        </tr>
      </table>

      <!-- Braun table -->
      <table v-if="$store.state.brand.includes('Braun')">
        <th class="w-1/4 text-left font-black">Device ID</th>
        <th class="w-1/4 text-left font-black">Session ID</th>
        <th class="w-1/4 text-left font-black">Duration</th>
        <th class="max-w-1/4 text-left font-black">Intensity</th>
        <tr v-for="session in sessions" :key="session.sessionId">
          <td>{{ session.deviceId }}</td>
          <td>{{ session.sessionId }}</td>
          <td class="text-center">{{ session.duration }}</td>
          <td class="w-0">{{ session.intensity }}</td>
        </tr>
      </table>
    </div>
    <router-link
      to="/sessions"
      class="text-gray-500 p-5 italic absolute bottom-0 right-0"
      >View sessions page</router-link
    >
  </div>
  <!--/Quickview Card-->
</template>

<script>
import brandConstants from '@/util/brandConstants';
import currentBrand from '@/util/getCurrentBrand';
import TwoDates from '@/components/UI/TwoDates';

export default {
  data () {
    return {
      brandConstants,
      brand: currentBrand()
    };
  },
  components: {
    TwoDates
  },
  computed: {
    sessions () {
      return this.$store.state.sessions.slice(0, 6);
    }
  }
};
</script>
