<template>
  <!--Oral-B Devices Quickview Card-->
  <div class="bg-white border rounded shadow quickview relative">
    <div class="border-b p-3">
      <router-link to="/devices" class="font-bold uppercase homepageLink"
        >Devices Quickview</router-link
      >
    </div>
    <div v-if="devices.length === 0" class="p-5">No Devices Available</div>
    <div v-else class="p-5 overflow-auto">
      <!-- orab table -->
      <table
        v-if="$store.state.brand.includes('Oral-B')"
      >
        <th class="w-1/5 text-left font-black">Device Type</th>
        <th class="w-1/5 text-left font-black">Device ID</th>
        <th class="w-1/5 text-left font-black">Mac Address</th>
        <th class="w-1/5 text-left font-black">Status</th>
        <th class="w-1/5 text-left font-black">Firmware Version</th>
        <tr v-for="device in devices" :key="device.deviceId">
          <td>{{ device.deviceType }}</td>
          <td>{{ device.deviceId }}</td>
          <td>{{ device.macAddress }}</td>
          <td>{{ device.status }}</td>
          <td>{{ device.firmwareVersion }}</td>
        </tr>
      </table>

      <!-- opte table -->
      <table v-if="$store.state.brand === $store.state.brandConstants.opte">
        <th class="w-1/5 text-left font-black">Device Type</th>
        <th class="w-1/5 text-left font-black">Device ID</th>
        <th class="w-1/5 text-left font-black">Base Hardware Model</th>
        <th class="w-1/5 text-left font-black">Base Hardware Version</th>
        <tr v-for="device in devices" :key="device.deviceId">
          <td>{{ device.deviceType }}</td>
          <td>{{ device.deviceId }}</td>
          <td>{{ device.baseHardwareModel }}</td>
          <td>{{ device.baseHardwareVersion }}</td>
        </tr>
      </table>

      <!-- gillette table -->
      <table v-if="$store.state.brand.includes('Gillette')">
        <th class="w-1/5 text-left font-black">Device Type</th>
        <th class="w-1/5 text-left font-black">Device ID</th>
        <th class="w-1/5 text-left font-black">Blade Use</th>
        <th class="w-1/5 text-left font-black">Blade Eject Time</th>
        <tr v-for="device in devices" :key="device.deviceId">
          <td>{{ device.deviceType }}</td>
          <td>{{ device.deviceId }}</td>
          <td>{{ device.bladeUse }}</td>
          <td>{{ device.bladeEjectTime }}</td>
        </tr>
      </table>

      <!-- labs table -->
      <table v-if="$store.state.brand.includes('Labs')">
        <th class="w-1/5 text-left font-black">Device Type</th>
        <th class="w-1/5 text-left font-black">Device ID</th>
        <th class="w-1/5 text-left font-black">ID 1</th>
        <th class="w-1/5 text-left font-black">String 1</th>
        <tr v-for="device in devices" :key="device.deviceId">
          <td>{{ device.deviceType }}</td>
          <td>{{ device.deviceId }}</td>
          <td>{{ device.id1 }}</td>
          <td>{{ device.string1 }}</td>
        </tr>
      </table>

      <!-- braun table -->
      <table v-if="$store.state.brand.includes('Braun')">
        <th class="w-1/5 text-left font-black">Device Type</th>
        <th class="w-1/5 text-left font-black">Device ID</th>
        <th class="w-1/5 text-left font-black">Device Name</th>
        <th class="w-1/5 text-left font-black">Created By</th>
        <tr v-for="device in devices" :key="device.deviceId">
          <td>{{ device.deviceType }}</td>
          <td>{{ device.deviceId }}</td>
          <td>{{ device.deviceName }}</td>
          <td>{{ device.createdBy }}</td>
        </tr>
      </table>
    </div>
    <router-link
      to="/devices"
      class="text-gray-500 p-5 italic absolute bottom-0 right-0"
      >View devices page</router-link
    >
  </div>
</template>

<script>
export default {
  computed: {
    devices () {
      return this.$store.state.devices.slice(0, 6);
    }
  }
};
</script>
