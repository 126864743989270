<template>
  <div id="user-details" class="flex flex-wrap mb-14 justify-start">
    <div class="p-5">
      <table>
          <tr>
          <td>Email:</td>
          <td>{{ $store.state.user.attributes.email }}
            <span v-if="!$store.state.user.attributes.email_verified">
              <router-link
                to="/verify"
                class="pl-5"
              >
                Verify Email
              </router-link>
            </span>
          </td>
        </tr>
        <tr>
          <td>First Name:</td>
          <td>{{ $store.state.user.attributes.given_name || "N/A"}}</td>
        </tr>
        <tr>
          <td>Last Name:</td>
          <td>{{ $store.state.user.attributes.family_name || "N/A"}}</td>
        </tr>
        <tr>
          <td>MFA:</td>
          <td>{{ $store.state.user.preferredMFA }}</td>
        </tr>
        <tr>
          <td>Locale:</td>
          <td>{{ $store.state.user.attributes.locale || "N/A"}}</td>
        </tr>
        <tr>
          <td>AppName:</td>
          <td>{{ $store.state.user.attributes.website || "N/A"}}</td>
        </tr>
        <tr>
          <td>User Pool ID:</td>
          <td>{{ $store.state.user.pool.userPoolId }}</td>
        </tr>
        <tr>
          <td>Consumer ID:</td>
          <td
            class="relative cursor-pointer"
            @click="copy($store.state.user.username, 'ID')"
          >
            {{ $store.state.user.username }}
            <FontAwesomeIcon class="ml-2" icon="copy" />
            <div
              v-if="showCopied === 'ID'"
              class="anim-object anim-smallcar success bg-green-500 text-white font-bold py-2 px-4 rounded-full"
            >
              Copied
              <FontAwesomeIcon class="ml-2" icon="check" />
            </div>
          </td>
        </tr>
        <tr>
          <td>JWT Token:</td>
          <td
            class="relative cursor-pointer"
            @click="copy($store.state.user.idToken.jwtToken, 'JWT')"
          >
            {{ $store.state.user.idToken.jwtToken.substring(0, 25) + "..." }}
            <FontAwesomeIcon class="ml-2" icon="copy" />
            <div
              v-if="showCopied === 'JWT'"
              class="anim-object anim-smallcar success bg-green-500 text-white font-bold py-2 px-4 rounded-full"
            >
              Copied
              <FontAwesomeIcon class="ml-2" icon="check" />
            </div>
          </td>
        </tr>
        <tr>
          <td>Access Token:</td>
          <td
            class="relative cursor-pointer"
            @click="copy($store.state.user.accessToken.jwtToken, 'ACCESS')"
          >
            {{ $store.state.user.accessToken.jwtToken.substring(0, 25) + "..." }}
            <FontAwesomeIcon class="ml-2" icon="copy" />
            <div
              v-if="showCopied === 'ACCESS'"
              class="anim-object anim-smallcar success bg-green-500 text-white font-bold py-2 px-4 rounded-full"
            >
              Copied
              <FontAwesomeIcon class="ml-2" icon="check" />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import startCase from 'lodash/startCase';
export default {
  data () {
    return {
      showCopied: ''
    };
  },
  methods: {
    copy (text, logic) {
      this.showCopied = logic;
      const copyField = document.createElement('textarea');
      document.body.appendChild(copyField);
      copyField.value = text;
      copyField.select();
      document.execCommand('copy');
      document.body.removeChild(copyField);
      this.resetCopied();
    },
    resetCopied () {
      setTimeout(() => {
        this.showCopied = '';
      }, 2000);
    },
    camelToTitleCase (str) {
      return startCase(str);
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  position: absolute;
  top: -21px;
  right: -29px;
  opacity: 0;
  animation: opacityOn 3s normal forwards;
}
@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
